<template>
  <label class="block w-full">
      <div :class="containerClasses">
          <div class="flex" :class="labelClasses">
              <div
                  class="text-md text-gray-600 dark:text-white/75 whitespace-nowrap label"
                  :class="status === 'error' ? 'text-red-400' : ''"
                  v-html="label">
              </div>
              <n-popover
                  v-if="help"
                  trigger="hover"
                  class="max-w-[500px] bg-main rounded-md"
                  placement="top">
                  <template #trigger>
                      <n-icon class="text-main cursor-pointer text-xl mx-2">
                          <QuestionCircle20Filled />
                      </n-icon>
                  </template>
                  <span class="text-xs text-white" v-html="help"></span>
              </n-popover>
              <n-popover
                  v-if="helpWarning"
                  trigger="hover"
                  class="max-w-[500px] rounded-md bg-yellow-600"
                  placement="top">
                  <template #trigger>
                      <n-icon
                          class="cursor-pointer text-lg ml-2 text-yellow-600">
                          <Warning24Filled />
                      </n-icon>
                  </template>
                  <span class="notes text-xs text-white" v-html="helpWarning"></span>
              </n-popover>
          </div>
  
          <section
              class="flex flex-grow"
              :class="inputClasses">
              <slot name="beforeInput"></slot>
              <n-input-number
                  clearable
                  class="rounded-md w-full"
                  show-password-on="mousedown"
                  :min="min"
                  :max="max"
                  :size="size"
                  :type="type"
                  :value="value"
                  :status="status"
                  :disabled="disabled"
                  :placeholder="placeholder"
                  :allow-input="allowInput"
                  @update:value="$emit('update:value', $event)">
                  <template #suffix><slot name="suffix"></slot></template>
              </n-input-number>
          </section>
      </div>
      <div v-if="status === 'error'" class="w-full mt-2 text-red-400 text-right">{{ msg }}</div>
      <n-divider v-if="divider" />
  </label>
  </template>
  
<script>
// vue
import { computed } from 'vue';
  
// naive-ui
import {
    NIcon,
    NDivider,
    NPopover,
    NInputNumber } from 'naive-ui';
  
// icons
import { Warning24Filled, QuestionCircle20Filled } from '@vicons/fluent';
  
export default {
    name: 'rb-input',
    emits: ['update:value'],
    props: {
        label: {
            type: String,
            required: true,
        },
        labelPosition: {
            type: String,
            default: 'top',
            validator: prop => ['left', 'top'].includes(prop),
        },
        size: {
            type: String,
            default: 'medium',
            validator: prop => ['tiny', 'small', 'medium', 'large'].includes(prop),
        },
        help: {
            type: String,
        },
        helpWarning: {
            type: String,
        },
        helpPosition: {
            type: String,
            default: 'right',
        },
        value: {
            required: true,
        },
        placeholder: {
            type: String,
            default: null,
        },
        status: {
            type: String,
            default: undefined,
        },
        msg: {
            type: String,
        },
        type: {
            type: String,
            default: 'text',
            validator: prop => ['text', 'number', 'password'].includes(prop),
        },
        divider: {
            type: Boolean,
            default: false,
        },
        labelWidth: {
            type: [ String, Number ],
            default: 105,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        min: {
            type: Number,
        },
        max: {
            type: Number,
        },
    },
    components: {
        NIcon,
        NPopover,
        NDivider,
        NInputNumber,
        Warning24Filled,
        QuestionCircle20Filled,
    },
    setup(props) {
        const allowInput = v => {
            if (props.type === 'number') {
                return !v || /^\d+$/.test(v);
            } else if (props.type === 'price') {
                return !v || /^[0-9]+(?:\.\d{0,2})?$/.test(v);
            }
                  
            return true;
        };
  
        const containerClasses = computed(() => {
            return [
                props.labelPosition === 'left' ? 'flex justify-between' : '',
                props.type === 'textarea' ? 'items-start' : 'items-center',
            ];
        });
  
        const labelClasses = computed(() => {
            return [
                // props.labelPosition === 'left' ? '' : '',
                props.type === 'textarea' ? 'mt-2' : '',
                props.help ? '' : 'pr-3',
            ];
        });
  
        const inputClasses = computed(() => {
            return props.labelPosition === 'left'
                ? ''
                : props.label ? 'mt-2' : '';
        });
  
        return {
            allowInput,
            inputClasses,
            labelClasses,
            containerClasses,
        };
    },
};
</script>
  
  <style lang="scss" scoped>
  :deep(.label) {
      & b {
          color: #4949D9;
      }
  }
  </style>