<template>
<section>
    <div class="flex flex-wrap">
        <div
            v-if="label"
            class="text-gray-600 dark:text-white/75 text-xs whitespace-nowrap"
            :class="status === 'error' ? 'text-red-400' : ''">
            {{ label }}
        </div>
        <slot name="afterTitle"></slot>
        <n-popover
            v-if="help"
            trigger="click"
            class="max-w-[200px] rounded-md"
            :class="popoverType === 'info' ? 'bg-main' : 'bg-yellow-600'">
            <template #trigger>
                <n-icon
                    class="text-lg ml-2"
                    :class="popoverType === 'info' ? 'text-main' : 'text-yellow-600'">
                    <QuestionCircle20Filled />
                </n-icon>
            </template>
            <span class="notes text-[10px] text-white" v-html="help"></span>
        </n-popover>
    </div>
    <n-button
        :size="size"
        tertiary
        class="select-button w-full rounded-md pl-2 pr-2"
        :disabled="disabled || loading"
        :class="label ? 'mt-2' : ''"
        @click="showDrawer = true">
        <div class="w-full flex justify-between items-center">
            <div
                v-if="value"
                class="flex items-center w-[calc(100%-24px)] overflow-x-auto scrollbar-hide">
                <span class="text-gray-600 dark:text-white/75 text-xs pl-2">{{ currentValue?.label || placeholder }}</span>
            </div>
            <span v-else
                class="text-gray-600/50 dark:text-white/40">{{ placeholder }}</span>
            <n-icon size="18"><CaretDown12Filled /></n-icon>
        </div>
    </n-button>
    <n-drawer
        v-model:show="showDrawer"
        width="100%"
        display-directive="show"
        class="bg-gray-100 dark:bg-darkbg"
        :auto-focus="false">
        <n-drawer-content body-content-style="padding: 0">
            <div class="flex flex-col h-full">
                <div class="w-full flex justify-between px-3 items-center bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-2">
                    <n-icon
                        size="20"
                        class="text-gray-600 dark:text-white/75"
                        @click="showDrawer = false">
                        <ArrowLeft12Regular />
                    </n-icon>
                    <rb-input v-model:value="query" class="ml-3" />
                    <n-button
                        quaternary
                        class="bg-transparent text-main pr-0"
                        @click="showDrawer = false">
                        {{ t('done') }}
                    </n-button>
                </div>
                <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide px-3">
                    <section class="flex flex-wrap">
                        <template
                          v-for="(item, i) in optionsRef" :key="i">
                          <div v-show="item.show" class="w-4/12">
                              <div
                                  class="cursor-pointer border border-transparent hover:border-main p-2 rounded-md block"
                                  :class="item.id === value ? 'border-main bg-main bg-opacity-25' : ''"
                                  @click="$emit('update:value', item.id), showDrawer = false">
                                  <div class="flex items-center justify-center">
                                      <rb-coin class="w-6" :coin="currency(item.value, false)[0]" />
                                      <div class="text-center px-3 whitespace-nowrap">{{ item.value }}</div>
                                      <rb-coin class="w-6" :coin="currency(item.value, false)[1]" />
                                  </div>
                                </div>
                          </div>
                        </template>
                    </section>
                </div>
            </div>
        </n-drawer-content>
    </n-drawer>
</section>
<!-- <div>
    <div v-if="label" class="flex">
        <div
            class="text-md text-gray-600 dark:text-white/75 whitespace-nowrap"
            :class="status === 'error' ? 'text-red-400' : ''">{{ label }}</div>
        <n-popover
            v-if="help"
            trigger="hover"
            class="max-w-[500px] rounded-md"
            :class="popoverType === 'info' ? 'bg-main' : 'bg-yellow-600'"
            :placement="helpPosition">
            <template #trigger>
                <n-icon
                    class="text-main cursor-pointer text-lg ml-2"
                    :class="popoverType === 'info' ? 'text-main' : 'text-yellow-600'">
                    <QuestionCircle20Filled />
                </n-icon>
            </template>
            <span class="notes text-xs text-white" v-html="help"></span>
        </n-popover>
    </div>

    <n-select
        filterable
        class="mt-2 text-left"
        :size="size"
        :status="status"
        :loading="loading"
        :disabled="disabled || loading"
        :options="pairsRefsOptions"
        :placeholder="placeholder"
        :render-label="renderLabel"
        :value="value !== -1 ? value : undefined"
        @update:value="$emit('update:value', $event)"/>
    <div v-if="status === 'error'" class="w-full mt-2 text-red-400 text-right">{{ msg }}</div>
</div> -->
</template>

<script>
// vue
import { h, ref, computed } from 'vue';

// store
import { useRefs } from '@/store/refs';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input/mobile.vue';
import GridNav from '@components/base/grid-nav.vue';

// i18n
import { useI18n } from 'vue-i18n';

// naive-ui
import {
    NIcon,
    NAlert,
    NDrawer,
    NButton,
    NSelect,
    NDivider,
    NPopover,
    NDrawerContent } from 'naive-ui';

// icons
import { CaretDown12Filled, Warning24Filled, QuestionCircle20Filled, ArrowLeft12Regular } from '@vicons/fluent';

export default {
    name: 'RbPairs',
    emits: ['update:value'],
    props: {
        disabled: Boolean,
        loading: Boolean,
        label: {
            type: String,
        },
        labelPosition: {
            type: String,
            default: 'top',
            validator: prop => ['right', 'top'].includes(prop),
        },
        size: {
            type: String,
            default: 'medium',
            validator: prop => ['tiny', 'small', 'medium', 'large'].includes(prop),
        },
        help: {
            type: String,
        },
        largeHelp: {
            type: Boolean,
        },
        helpPosition: {
            type: String,
            default: 'right',
        },
        value: {
            type: [String, Number],
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        placeholder: {
            type: String,
            default: () => useI18n().t('select'),
        },
        status: {
            type: String,
            default: undefined,
        },
        msg: {
            type: String,
        },
        popoverType: {
            type: String,
            default: 'info',
            validator: prop => ['info', 'warning'].includes(prop), 
        },
    },
    components: {
        NIcon,
        NAlert,
        RbCoin,
        RbInput,
        NDrawer,
        NSelect,
        NButton,
        GridNav,
        NDivider,
        NPopover,
        NDrawerContent,
        Warning24Filled,
        CaretDown12Filled,
        ArrowLeft12Regular,
        QuestionCircle20Filled,
    },
    setup(props) {
        // store
        const refs = useRefs();

        // i18n
        const { t } = useI18n();

        // vars
        const query = ref('');
        const showDrawer = ref(false);

        const mainBlockClasses = computed(() => {
            if (props.labelPosition === 'left') {
                return 'flex items-center';
            }

            return null;
        });

        const optionsRef = computed(() => {
            const res = props.options.map(el => {
                el.show = el.label?.toLowerCase().includes(query.value.toLowerCase());
                
                return el;
            });

            return res;
        });

        const currency = (icon, first = true) => {
            const lIcon = first ? icon.split('/').shift().trim() : icon.split('/');

            const res = Array.isArray(lIcon)
                ? [...lIcon.map(curIcon => refs.currencies.find(el => el.id === curIcon.split('__')[0]))]
                : refs.currencies.find(el => el.id === lIcon);

            return res;
        };

        const pairsRefsOptions = computed(() => {
            const arr = props.options.map(cur => ({
                label: cur.title,
                value: cur.id,
                ...cur,
            }));

            arr.unshift({
                label: props.placeholder,
                value: null,
                style: {
                    color: 'gray',
                },
            });

            return arr;
        });

        const renderLabel = (option) => {
            return !option.value
                ? h('div', {
                    class: 'px-2 text-base',
                }, [option.label])
                : h(
                    'div',
                    {
                        class: 'flex items-center py-1',
                    },
                    [
                        h(RbCoin, {
                            class: 'w-4',
                            coin: currency(option.value, false)[0],
                        }),
                        h(
                            'div',
                            {
                                class: 'px-3',
                            },
                            [
                                h('span', {
                                    class: 'text-xs',
                                }, option.label.split('/')[0]),
                                h('span', {
                                    class: 'opacity-50 px-1',
                                }, '/'),
                                h('span',  {
                                    class: 'text-xs',
                                }, option.label.split('/')[1]),
                            ],
                        ),
                        h(RbCoin, {
                            class: 'w-4',
                            coin: currency(option.value, false)[1],
                        }),
                    ],
                );
        };

        const currentValue = computed(() => {
            let result;

            for (const item of optionsRef.value) {
                if (item.value != props.value && Object.prototype.hasOwnProperty.call(item, 'children')) {
                    const child = item.children.find(({ value }) => value == props.value);

                    if (child) {
                        result = child;
                        break;
                    }
                } else if (item.value == props.value) {
                    result = item;
                    break;
                }
            }

            return result;
        });
        
        return {
            query,
            showDrawer,
            optionsRef,
            currentValue,
            pairsRefsOptions,
            mainBlockClasses,
            t,
            currency,
            renderLabel,
        };
    },
};
</script>

<style lang="scss" scoped>
.select-button :deep(.n-button__content) {
    width: 100%;
}
</style>