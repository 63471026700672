import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createI18n, useI18n } from 'vue-i18n';

// components
import RbLoader from '@components/rb-loader';
import RbInput from '@components/rb-input';
import RbTitle from '@components/rb-title';
import RbCoin from '@components/rb-coin';
import RbHelp from '@components/rb-help';
import RbCheckbox from '@components/rb-checkbox';
import RbInputNumber from '@components/rb-input-number';
import BaseDrawerMobile from '@components/base-drawer/mobile.vue';
import BaseModalDesktop from '@components/base-modal/desktop.vue';
import BaseModalMobile from '@components/base-modal/mobile.vue';
import BaseDialogDesktop from '@components/base-dialog/desktop.vue';
import BaseDialogMobile from '@components/base-dialog/mobile.vue';

import { languages, defaultLocale } from './i18n';
window._ = require('lodash');
window.isValidJSON = str => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

import './css/main.scss';

import App from './App.vue';
import router from './router';
import axios from 'axios';

import { useUser } from './store/user';

const pinia = createPinia();
const app = createApp(App, {
    setup() {
        return { t: useI18n() };
    },
});

app
    .use(router)
    .use(pinia)
    .component('RbCoin', RbCoin)
    .component('RbHelp', RbHelp)
    .component('RbTitle', RbTitle)
    .component('RbLoader', RbLoader)
    .component('BaseModalDesktop', BaseModalDesktop)
    .component('BaseModalMobile', BaseModalMobile)
    .component('RbInput', RbInput)
    .component('RbInputNumber', RbInputNumber)
    .component('BaseDrawerMobile', BaseDrawerMobile)
    .component('RbCheckbox', RbCheckbox)
    .component('BaseDialogDesktop', BaseDialogDesktop)
    .component('BaseDialogMobile', BaseDialogMobile);

const user = useUser();

const draftData = {
    profile: {
        name: 'Drew',
        lang: 'ru',
        id: 70419,
        email: 'karchev_sait@mail.ru',
    },
    build_timestamp: 1655742061,
    auth: {
        token: '88ed0dec-81ec-11ed-9d2c-caaff8d58dd3',
    },
};


axios('https://app.revenuebot.io/auth?json_resp=1&version=2.0.0', {
    method: 'get',
    crossDomain: true,
    withCredentials: true,
}).then(({ data }) => {
    const info = JSON.parse(data.rb);

    window.rb = info;

    if (!info.auth.token) {
        if (process.env.NODE_ENV === 'production') {
            document.location.href = 'https://revenuebot.io/';
        } else {
            for(const [key, value] of Object.entries(draftData)) {
                user.$patch({ [key]: value });
            }
        }
    } else {
        for(const [key, value] of Object.entries(info)) {
            user[key] = value;
        }
    }

    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(data.public_html, 'text/html');

    const headChilds = htmlDoc.head.childNodes;
    headChilds.forEach(el => {
        if (el.tagName == 'SCRIPT') {
            let newEl = document.createElement('script');
            newEl.type = 'text/javascript';
            newEl.async = el.async;

            if (el.src) {
                newEl.src = el.src;
            } else {
                newEl.innerHTML = el.innerHTML;
            }

            document.head.appendChild(newEl);
        } else if (el.tagName) {
            document.head.appendChild(el);
        }
    });

    const i18n = createI18n({
        locale: user.profile.lang,
        legacy: false,
        messages: Object.assign(languages),
    });
    
    app.use(i18n).mount('#app');
});

